
<template>
  <div v-bind:style="{ padding: '10px', height: '100%',display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }">
    <div>
    <div :style="{display: 'flex', justifyContent: 'space-between', alignItems: 'end'}">
      <span :style="{ padding: '10px 5px', fontSize: '1rem', fontWeight: '600', color: '#000' }">{{ title === 'latest' ? '최근 게시글' : title }}</span>
      <span v-show="this.$route.name === 'dashboard.latest'" :style="{ padding: '10px', fontSize: '0.8rem', fontWeight: '600', color: '#000'}" @click="more">more ></span>
    </div>
    <b-table
        id="list-table"
        responsive
        :small="true"
        :items="items"
        :fields="fieldsType === 'list' ? ListFields : SpanFields"
        :busy="isBusy"
        :hover="true"
        :fixed="true"
        :per-page="perPage"
        :no-border-collapse="true"
        :tbody-tr-class="rowClass"
        >

      <!-- 테이블 로딩 시작-->
      <template #table-busy>
        <div class="text-center text-danger my-2">
          <b-spinner class="align-middle" :style="{color: '#032D98', paddingRight: '10px'}"></b-spinner>
        </div>
      </template>
      <!-- 테이블 로딩 끝-->

      <!-- 작성일 변경 -->
      <template #cell(date)="data">
        <span :style="{ fontSize: '1rem', color: '#7E7E7E', fontWeight: 500 }">
          {{ (data.value) }}
        </span>
      </template>

      <!-- 작성일 변경 -->
      <template #cell(coin)="data">
        <span :style="{ fontSize: '1rem', color: '#7E7E7E', fontWeight: 500 }">
          {{ (data.value).toLocaleString() }} TIPS
        </span>
      </template>

    </b-table>
    </div>
    <div>
    <b-pagination
    class="customPagination"
      v-model="currentPage"
      align='center'
      @page-click="pageClick"
      :total-rows="total"
      :per-page="perPage"
      aria-controls="list-table"
      :first-number="true"
      :last-number="true" />
    </div>
  </div>
</template>
<script>
export default {
  name: 'StakingListForm',
  props: {
    isBusy: { type: Boolean },
    title: { type: String },
    items: { type: Array },
    total: { type: Number }, // 카테고리별 총 개수
    fieldsType: { type: String },
    color: {},
    getMore: {},
    path: {}
  },
  mounted () {
  },
  methods: {
    more () {
      this.$router.push({ name: `dashboard.${this.path}` })
    },
    rowClass (item, type) {
      if (item && type === 'row') {
        if (item.highlight) {
          return 'font-weight-bold'
        } else {
          return 'font-weight-light'
        }
      } else {
        return null
      }
    },
    pageClick (button, page) {
      this.$emit('setBusy')
      this.getMore(page - 1, this.search)
        .then(res => {
          if (res.status !== 200) {
            return alert('서버 오류')
          }
          if (res.data.resultCode === 'false') {
            alert('서버 오류')
            this.$emit('setBusy')
            return
          }
          this.$emit('setBusy')
          this.$emit('setItem', res.data.list, res.data.total)
        })
    }
  },
  computed: {
    formartedItems () {
      if (!this.items) return []
      return this.items.map(item => {
        if (item.highlight) {
          item._rowVariant = 'warning'
        }
        return item
      })
    }
  },
  data () {
    return {
      search: '',
      category: this.$route.params.category,
      itemList: [],
      perPage: 10, // 1페이지당 몇개씩
      currentPage: 1, // 현재 페이지
      SpanFields: [
        {
          key: 'title',
          label: '',
          thStyle: { display: 'none' },
          tdClass: 'text-start',
          thClass: 'text-start bg-white text-dark'
        }],
      ListFields: [
        {
          key: 'date',
          label: '지급일',
          thStyle: { background: this.$props.color },
          tdClass: 'text-center align-middle text-truncate',
          thClass: 'text-center align-middle text-truncate'
        },
        {
          key: 'coin',
          label: '이자',
          thStyle: { background: this.$props.color },
          tdClass: 'text-center align-middle text-truncate',
          thClass: 'text-center align-middle text-truncate'
        }
      ]
    }
  }
}
</script>
<style>
.page-item .page-link {
    background-color: #fff !important;
    color: #000 !important;
    border-color: #797979 !important;
}
</style>
